import { FiltersType } from 'types/filters';
import {  isArray } from 'lodash';
import { FeedType } from '../types/feed';
import { ModelFamilyType } from '../types/modelFamily';
import { FilterReviewsType } from '../pages/Reviews/types';

export const GROUP_PREFIX = `group_`;

type ActiveFiltersType = FiltersType & FeedType & FilterReviewsType;

export const paramsFromActiveFilters = (object: ActiveFiltersType) => {
	const urlParams: string[] = [];

	Object.keys(object).forEach((key) => {
		if (isArray(object[key as keyof ActiveFiltersType])) {
			const arr = object[key as keyof ActiveFiltersType] as Array<number | string>;
			if (arr.length > 0) {
				urlParams.push(`${encodeURIComponent(key)}[]=${arr.join(';')}`);
			}
		} else {
			urlParams.push(
				`${encodeURIComponent(key)}=${encodeURIComponent(
					object[key as keyof ActiveFiltersType] as string | number | boolean,
				)}`,
			);
		}
	});

	return urlParams.join('&');
};

export const makeModelsFromDataWithGroup = (data: ModelFamilyType[]) => {
	if (!data) {
		return [];
	}

	const groups:Record<string | number, {name: string, models: [{label: string, value: number, nested: boolean}]}> = {};
	
	for(const item of data) {
		if (item.model_family_group){
			const {id, name} = item.model_family_group;

			if(!groups[id]) {
				groups[id] = {
					name,
					models: [{
						value: item.id,
						label: item.name,
						nested: true,
					}],
				};
			} else {
				groups[id].models.push({
					value: item.id,
					label: item.name,
					nested: true,
				});
			}
		}
	}

	const models = [];
	for(const groupId in groups){
		models.push({ value: `${GROUP_PREFIX}${groupId}`, label: groups[groupId].name});
		models.push(...groups[groupId].models);
	}

	return models;
};
