import React, {
	FunctionComponent,
	useState,
	useEffect,
	useMemo,
	useCallback,
	ReactElement,
} from 'react';
import styled from 'styled-components/macro';
import Layout from 'components/PrivateLayout';
import { Icon, Button, Input, Form, Radio, Switch, notification } from 'antd';
import { Trans, t } from '@lingui/macro';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import apiFetch from 'utils/apiFetch';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import antlr4, { Lexer, Parser } from 'antlr4';
import { RuleContext } from 'antlr4/RuleContext';
import { ParseTreeVisitor } from 'antlr4/tree/Tree';
import { useLingui } from '@lingui/react';
import AdvertisingRulePreviewModal from 'components/modals/AdvertisingRulePreviewModal';

import { ADVERTISING_RULES_LISTING_PATHNAME } from '../../pathnames';
import { AdvertisingRulesLexer } from './generated/AdvertisingRulesLexer';
import { AdvertisingRulesParser } from './generated/AdvertisingRulesParser';
import { CancelConfirmModal } from './modals/CancelConfirmModal';
import { MenuItemContainer } from './components/MenuItemContainer';
import ErrorCollectingErrorListener, {
	EndOfInputSyntaxError,
	TokenSyntaxError,
} from "./ErrorCollectingErrorListener";
import EntityPropertiesValidationVisitor, {
	InvalidEntityError,
	InvalidEntityPropertyError,
} from "./EntityPropertiesValidationVisitor";
import QueryTooltip from "./components/QueryTooltip";
import { AdvertisingRule } from '../../types/advertisingRules';

const StyledLink = styled(Link)`
	color: #000;
`;

const StyledIcon = styled(Icon)`
	padding: 0 15px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-x: auto;
`;

const MenuLabel = styled.div`
	margin-bottom: 20px;
	font-weight: 600;
`;

const MenuForm = styled.div`
	display: flex;
	padding: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #fff;
`;

const MenuFooter = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const TestButton = styled(Button)`
	margin-right: 15px;
`;

type AdvertisingRuleEditProps = { form: WrappedFormUtils } & RouteComponentProps<{ id?: string }>;

interface FormValues {
	readonly name: string;
	readonly description?: string;
	readonly active: boolean;
	readonly carQuery: string;
	readonly userQuery: string;
}

interface AdvertisingRulesParserType extends Parser {
	start(): RuleContext;
}

const carQueryEntityProperties = new Map([
	['car', new Set(['country', 'zip', 'mileage', 'age', 'numberOfPhotos', 'source', 'vehicleTag'])],
	['seller', new Set(['type', 'name', 'rating', 'numberOfRatings', 'numberOfCarsOnStock'])],
]);

const userQueryEntityProperties = new Map([
	['user', new Set(['type', 'country', 'zip'])],
	['seller', new Set(['type'])],
	['account', new Set(['id'])],
]);

const parseQuery = (
	input: string,
	entityProperties: ReadonlyMap<string, ReadonlySet<string>>,
): readonly Error[] => {
	const inputStream = new antlr4.InputStream(input);
	const lexer: Lexer = new AdvertisingRulesLexer(inputStream) as any;
	const tokenStream = new antlr4.CommonTokenStream(lexer);
	const parser: AdvertisingRulesParserType = new AdvertisingRulesParser(tokenStream) as any;
	const errorCollectingErrorListener = new ErrorCollectingErrorListener();
	parser.removeErrorListeners();
	parser.addErrorListener(errorCollectingErrorListener);

	const tree = parser.start();

	const entityPropertiesValidationVisitor = new EntityPropertiesValidationVisitor(entityProperties);

	tree.accept((entityPropertiesValidationVisitor as any) as ParseTreeVisitor);

	return [
		...errorCollectingErrorListener.getParseErrors(),
		...entityPropertiesValidationVisitor.getValidationErrors(),
	];
};

const getErrorMessage = (error: Error): ReactElement => {
	if (error instanceof EndOfInputSyntaxError) {
		return <Trans>ADVERTISING_RULE_EDIT_END_OF_INPUT_SYNTAX_ERROR</Trans>;
	}
	if (error instanceof TokenSyntaxError) {
		return <Trans id="ADVERTISING_RULE_EDIT_TOKEN_SYNTAX_ERROR">{error.token}</Trans>;
	}
	if (error instanceof InvalidEntityError) {
		return <Trans id="ADVERTISING_RULE_EDIT_INVALID_ENTITY_ERROR">${error.entity}</Trans>;
	}
	if (error instanceof InvalidEntityPropertyError) {
		return (
			<Trans id="ADVERTISING_RULE_EDIT_INVALID_ENTITY_PROPERTY_ERROR">
				${error.property}${error.entity}
			</Trans>
		);
	}
	return <Trans>ADVERTISING_RULE_EDIT_UNEXPECTED_ERROR</Trans>;
};

const createQueryValidator = (entityProperties: ReadonlyMap<string, ReadonlySet<string>>) => (
	rule: unknown,
	value: string,
	callback: (error?: ReactElement) => void,
): void => {
	if (value === '' || value == null) {
		callback();
		return;
	}

	const errors = parseQuery(value, entityProperties);
	if (errors.length > 0) {
		callback(getErrorMessage(errors[0]));
	} else {
		callback();
	}
};

const AdvertisingRuleEdit: FunctionComponent<AdvertisingRuleEditProps> = ({
	form: { getFieldDecorator, validateFields },
	history,
	match,
}: AdvertisingRuleEditProps): ReactElement => {
	const editing = match.params.id != null;
	const { i18n } = useLingui();

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [isFormModalVisible, setIsFormModalVisible] = useState(false);
	const [userQuery, setUserQuery] = useState<string | null>(null);
	const [carQuery, setCarQuery] = useState<string | null>(null);

	const { data, isLoading, fetchData } = useFetch<AdvertisingRule>(
		'/api/carvago-admin/advertising-rule/:id',
		{ lazy: true },
	);

	const defaultValues = useMemo(
		(): FormValues => ({
			name: data?.name ?? '',
			description: data?.description ?? '',
			active: data?.active ?? true,
			carQuery: data?.advertised_car_query ?? '',
			userQuery: data?.user_query ?? '',
		}),
		[data],
	);

	useEffect((): void => {
		if (editing) {
			fetchData({ id: match.params.id }).catch((error: Error): void => {
				notification.error({
					message: i18n._(t`ADVERTISING_RULE_LOAD_ERROR`),
				});
				throw error;
			});
		}
	}, []);

	const handleTestButtonClick = useCallback((): void => {
		validateFields((errors: unknown): void => {
			if (errors != null) {
				return;
			}
			setIsFormModalVisible(!isCancelModalOpen);
		});
	}, [validateFields, setIsFormModalVisible]);

	const handleCancelButtonClick = useCallback((): void => {
		setIsCancelModalOpen(true);
	}, [setIsCancelModalOpen]);

	const handleSubmit = useCallback(
		(event: React.FormEvent): void => {
			event.preventDefault();
			validateFields((errors: unknown, values: FormValues): void => {
				if (errors != null) {
					return;
				}
				apiFetch(`/api/carvago-admin/advertising-rule${editing ? `/${match.params.id}` : ''}`, {
					method: editing ? 'PUT' : 'POST',
					body: JSON.stringify({
						name: values.name,
						description: values.description ?? '',
						active: values.active,
						advertised_car_query: values.carQuery,
						user_query: values.userQuery,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.catch((error: Error): void => {
						notification.error({
							message: i18n._(t`ADVERTISING_RULE_SAVE_ERROR`),
						});
						throw error;
					})
					.then((): void => {
						history.push(`/advertising-rules`);
					});
			});
		},
		[validateFields, editing, match],
	);

	const { state } = useLocation();
	const backLink = state?.carId != null
		? `/car-detail/${state.carId}?tab=BLACKLIST`
		: ADVERTISING_RULES_LISTING_PATHNAME;

	return (
		<Layout
			noSider
			header={
				<StyledLink to={backLink}>
					<StyledIcon type="arrow-left" />
					<Trans>ADVERTISING_RULE_LIST</Trans>
				</StyledLink>
			}
		>
			<Container>
				<MenuLabel>
					<Trans>ADVERTISING_RULE_EDIT</Trans>
				</MenuLabel>
				<Form onSubmit={handleSubmit}>
					<MenuForm>
						<MenuItemContainer
							labelTranslation={<Trans>ADVERTISING_RULE_NAME</Trans>}
							isLoading={isLoading}
						>
							<Form.Item>
								{getFieldDecorator('name', {
									initialValue: defaultValues.name,
									rules: [
										{
											required: true,
											message: <Trans>ERROR_REQUIRED_FIELD</Trans>,
										},
									],
								})(<Input />)}
							</Form.Item>
						</MenuItemContainer>

						<MenuItemContainer
							labelTranslation={<Trans>ADVERTISING_RULE_DESCRIPTION</Trans>}
							isLoading={isLoading}
						>
							<Form.Item>
								{getFieldDecorator('description', {
									initialValue: defaultValues.description,
								})(<Input.TextArea rows={5} />)}
							</Form.Item>
						</MenuItemContainer>

						<MenuItemContainer
							labelTranslation={<Trans>ADVERTISING_RULE_ACTIVE</Trans>}
							isLoading={isLoading}
						>
							<Form.Item>
								{getFieldDecorator('active', {
									valuePropName: 'checked',
									initialValue: defaultValues.active,
								})(<Switch />)}
							</Form.Item>
						</MenuItemContainer>

						<MenuItemContainer
							labelTranslation={
								<>
									<Trans>ADVERTISING_RULE_CAR_QUERY</Trans>
									&nbsp;
									<QueryTooltip
										title={<Trans>ADVERTISING_RULE_QUERY_TOOLTIP_TITLE_CAR</Trans>}
										entityProperties={carQueryEntityProperties}
										exampleQuery={
											'car.country = "CZ" AND (seller.type = "carvago" OR seller.rating > 4)'
										}
									/>
								</>
							}
							isLoading={isLoading}
						>
							<Form.Item>
								{getFieldDecorator('carQuery', {
									initialValue: defaultValues.carQuery,
									validateTrigger: 'onBlur',
									rules: [
										{
											required: true,
											message: <Trans>ERROR_REQUIRED_FIELD</Trans>,
										},
										{
											validator: createQueryValidator(carQueryEntityProperties),
										},
									],
								})(
									<Input.TextArea
										rows={5}
										onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
											setCarQuery(event.target.value);
										}}
									/>,
								)}
							</Form.Item>
						</MenuItemContainer>

						<MenuItemContainer
							labelTranslation={
								<>
									<Trans>ADVERTISING_RULE_USER_QUERY</Trans>
									&nbsp;
									<QueryTooltip
										title={<Trans>ADVERTISING_RULE_QUERY_TOOLTIP_TITLE_USER</Trans>}
										entityProperties={userQueryEntityProperties}
										exampleQuery={'user.country = "CZ" AND user.zipCode = "15500"'}
									/>
								</>
							}
							isLoading={isLoading}
						>
							<Form.Item>
								{getFieldDecorator('userQuery', {
									initialValue: defaultValues.userQuery,
									validateTrigger: 'onBlur',
									rules: [
										{
											required: true,
											message: <Trans>ERROR_REQUIRED_FIELD</Trans>,
										},
										{
											validator: createQueryValidator(userQueryEntityProperties),
										},
									],
								})(
									<Input.TextArea
										rows={5}
										onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
											setUserQuery(event.target.value);
										}}
									/>,
								)}
							</Form.Item>
						</MenuItemContainer>
						<MenuFooter>
							<Button type="link" onClick={handleCancelButtonClick}>
								<Trans>CANCEL</Trans>
							</Button>
							<div>
								<TestButton size="large" onClick={handleTestButtonClick}>
									<Trans id="TEST" />
								</TestButton>
								<Button type="primary" size="large" htmlType="submit">
									<Trans>SAVE</Trans>
								</Button>
							</div>
						</MenuFooter>
					</MenuForm>
				</Form>
			</Container>
			<CancelConfirmModal
				isModalOpened={isCancelModalOpen}
				onCancel={(): void => setIsCancelModalOpen(false)}
			/>
			<AdvertisingRulePreviewModal
				visible={isFormModalVisible}
				userQuery={userQuery ?? defaultValues.userQuery}
				carQuery={carQuery ?? defaultValues.carQuery}
				onCancel={(): void => setIsFormModalVisible(false)}
			/>
		</Layout>
	);
};

export default Form.create({ name: 'advertising_rule_edit' })(AdvertisingRuleEdit);
